<template>
<div class="main">
    <div :style="imageSrc ? {'background': imageSrc} : {}" class="p-12" @dragover="dragover" @dragleave="dragleave" @drop="drop">
        <input type="file" accept="image/png, image/jpeg, image/jpg" name="fields[assetsFieldHandle]" id="assetsFieldHandle" 
        class="w-px h-px opacity-0 overflow-hidden absolute" @change="onChange" ref="file" />

        <label for="assetsFieldHandle" class="block cursor-pointer">
        <div v-if="!filelist[0]" class="underline">
            {{ t('choosePhoto') }}
        </div>
        </label>
        <div class="mt-4 bg-white change-image" v-if="this.filelist.length" v-cloak>
            <button class="main px-2" type="button" @click="remove()" title="Remove file">
                <small>{{ t('changePhoto') }}</small>
            </button>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            filelist: [], // Store our uploaded files
            imageSrc: '#f7fafc',
        }
    },
    methods: {
        shortText(text) {
            if(text.length < 45) {
                return text
            } else {
                text = text.split('')
                text.length = 45
                return text.join('') + '...';
            }
        },
        onChange() {
            this.filelist = [...this.$refs.file.files];
        },
        remove() {
            this.filelist = [],
            this.imageSrc = '#f7fafc'
        },
        dragover(event) {
            event.preventDefault();
            // Add some visual fluff to show the user can drop its files
            if (!event.currentTarget.classList.contains('bg-green-300')) {
                event.currentTarget.classList.remove('bg-gray-100');
                event.currentTarget.classList.add('bg-green-300');
            }
        },
        dragleave(event) {
            // Clean up
            event.currentTarget.classList.add('bg-gray-100');
            event.currentTarget.classList.remove('bg-green-300');
        },
        drop(event) {
            event.preventDefault();
            this.$refs.file.files = event.dataTransfer.files;
            this.onChange(); // Trigger the onChange event manually
            // Clean up
            event.currentTarget.classList.add('bg-gray-100');
            event.currentTarget.classList.remove('bg-green-300');
        },
        setImageUrl(src) {
            this.imageSrc = `url(${src.result})`;
        }
    },
    watch: {
        filelist() {
            const reader = new FileReader()
            const file = this.filelist[0]
            const setImageUrl = this.setImageUrl
            reader.onloadend = function (e) {
                setImageUrl(e.target);
            }
            if(file) {
                reader.readAsDataURL(file)
            }
            this.$emit('imageChange', file)
        },
        bgImage() {
            if(this.bgImage) {
                this.imageSrc = `url(${this.bgImage})`;
                this.filelist = [this.bgImage];
            }
        }
    },
    props: [
        'bgImage'
    ]
}
</script>

<style scoped>
.change-image {
    transition: all 0.4s;
    padding: 10px;
    border-radius: 10px;
    opacity: 0;
}
.main:hover .change-image{
    opacity: 1;
}
.main {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.w-px {
    width: 1px;
}
.absolute {
    position: absolute;
}
.overflow-hidden {
    overflow: hidden;
}
.opacity-0 {
    opacity: 0;
}
.h-px {
    height: 1px;
}
button, input, optgroup, select, textarea {
    padding: 0;
    line-height: inherit;
    color: inherit;
}
.block {
    display: block;
}
.cursor-pointer {
    cursor: pointer;
}
.p-12 {
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position:center !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    width: 100%;
    height: 300px;
    border-radius: 10px;
}
.border {
    border-width: 1px;
}
.border-gray-300 {
    border-color: #e2e8f0;
}
.bg-gray-100 {
    background-color: #f7fafc;
}
[v-cloak] {
    display: none;
}
</style>