<template>
  <div class="home">
    <left-sidebar />
    <div class="mt-3 main">
      <div class="d-flex search-box">
        <input
          type="text"
          v-model="searchText"
          class="mb-3"
          :placeholder="t('search')"
        />
        <img src="@/assets/loupe.svg" class="search" alt="" />
      </div>
      <div v-if="!list.length" class="main d-flex justify-content-center">
        <b-spinner class="m-4" variant="success"></b-spinner>
      </div>
      <user-header
        v-for="company in filteredList"
        :key="company.name + company.id"
        :hideThumb="true"
        :company="true"
        :mainInfo="company"
        class="mb-2"
      />
    </div>
    <right-sidebar />
  </div>
</template>

<script>
import LeftSidebar from "../../components/LeftSidebar.vue";
import RightSidebar from "../../components/RightSidebar.vue";
import UserHeader from "../../components/UserHeader.vue";

export default {
  name: "IT_Companies",
  metaInfo() {
    return {
      title: "Techvibe " + this.t("itCompanies"),
      meta: [
        { name: "description", content: "Techvibe " + this.t("itCompanies") },
        { property: "og:title", content: "Techvibe " + this.t("itCompanies") },
        { property: "og:site_name", content: "Techvibe" },
        { property: "og:type", content: "website" },
      ],
      htmlAttrs: {
        lang: this.currLang,
      },
    };
  },
  data() {
    return {
      list: [],
      searchText: "",
    };
  },
  computed: {
    filteredList() {
      const searched = this.list.filter(
        (company) =>
          company.name.toLowerCase().includes(this.searchText.toLowerCase()) ||
          company.description
            .toLowerCase()
            .includes(this.searchText.toLowerCase())
      );
      return searched.sort((company) => (company.subscribe ? -1 : 1));
    },
  },
  async mounted() {
    document.title = this.t("itCompanies");
    this.list = await this.$store.dispatch("getITCompanies");
  },
  components: { UserHeader, LeftSidebar, RightSidebar },
};
</script>

<style scoped>
input {
  border: none;
  padding: 5px;
  padding-left: 15px;
  font-size: 20px;
  border-radius: 8px 0 0 8px;
  width: 750px;
}
.search {
  background-color: white;
  border-radius: 0 8px 8px 0;
  height: 40px;
  width: 40px;
  padding: 0 10px;
}
.router-link {
  max-width: max-content;
  padding: 3px 10px;
  margin: -3px -10px;
  margin-right: 50px;
}
.router-link:hover {
  border-radius: 15px;
  height: max-content;
  background-color: rgba(128, 128, 128, 0.1);
}
@media (max-width: 991px) {
  .search-box {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .main {
    margin-top: 10px !important;
  }
  .search-box > input {
    margin-bottom: 2px !important;
    border-radius: 0 !important;
  }
  .search-box > img {
    border-radius: 0 !important;
  }
  .header-card {
    margin-bottom: 2px !important;
  }
}
</style>